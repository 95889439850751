/*import * as echarts from "echarts"*/
const colors = ["#76db9b", "grey", "#EE6666"]
export const ReportChartsConfig = {
  /**
   * add config for timline chart form dhasboard and return object whit that config
   * @param {any} data
   * @returns {object}
   */

  salesTotal: function(data) {
   let size= Object.keys(data).length
    return {
      color: colors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross"
        }
      },
      grid: {
        right: "20%"
      },
      legend: {
        data: ["Quantity sales", "Income sales"]
      },
      xAxis: [
        {
          type: "category",
          name: "Days",
          axisTick: {
            alignWithLabel: true
          },
          // prettier-ignore
          data: size > 0 ? data.date : []
        }
      ],
      yAxis: [
        {
          type: "value",
          name: "Quantity sales",
          position: "right",
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[0]
            }
          },
          axisLabel: {
            formatter: "{value}"
          }
        },
        {
          type: "value",
          name: "Income sales",
          position: "left",
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[1]
            }
          },
          axisLabel: {
            formatter: "$ {value}"
          }
        }
      ],
      series: [
        {
          name: "Quantity sales",
          type: "bar",
          data: size > 0 ? data.sales_quantity : []
        },
        {
          name: "Income sales",
          type: "line",
          yAxisIndex: 1,
          data: size > 0 ? data.total_amount_sales : []
        }
      ]
    }
  },
  averageSalesByStore: function(data) {
    let size= Object.keys(data).length
    return {
      color: colors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross"
        }
      },
      grid: {
        right: "20%"
      },
      legend: {
        data: ["Quantity sales", "Average"]
      },
      xAxis: [
        {
          type: "category",
          name: "Store",
          axisTick: {
            alignWithLabel: true
          },
          // prettier-ignore
          data: size > 0 ? data.store : []
        }
      ],
      yAxis: [
        {
          type: "value",
          name: "Quantity sales",
          position: "right",
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[0]
            }
          },
          axisLabel: {
            formatter: "{value}"
          }
        },
        {
          type: "value",
          name: "Average",
          position: "left",
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[1]
            }
          },
          axisLabel: {
            formatter: "$ {value}"
          }
        }
      ],
      series: [
        {
          name: "Quantity sales",
          type: "bar",
          data: size > 0 ? data.sales_quantity : []
        },
        {
          name: "Average",
          type: "line",
          yAxisIndex: 1,
          data: size > 0 ? data.average_amout_sales : []
        }
      ]
    }
  },
  salesByStore: function(data) {
    let size= Object.keys(data).length
    return {
      color: colors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross"
        }
      },
      grid: {
        right: "20%"
      },
      legend: {
        data: ["Quantity sales", "Total income"]
      },
      xAxis: [
        {
          type: "category",
          name: "Store",
          axisTick: {
            alignWithLabel: true
          },
          // prettier-ignore
          data: size > 0 ? data.store : []
        }
      ],
      yAxis: [
        {
          type: "value",
          name: "Quantity sales",
          position: "right",
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[0]
            }
          },
          axisLabel: {
            formatter: "{value}"
          }
        },
        {
          type: "value",
          name: "Total income",
          position: "left",
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[1]
            }
          },
          axisLabel: {
            formatter: "$ {value}"
          }
        }
      ],
      series: [
        {
          name: "Quantity sales",
          type: "bar",
          data: size > 0 ? data.sales_quantity : []
        },
        {
          name: "Total income",
          type: "line",
          yAxisIndex: 1,
          data: size > 0 ? data.total_amount_sales : []
        }
      ]
    }
  },
  averageSaleEvolution: function(data) {
    let size= Object.keys(data).length
    return {
      color: colors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross"
        }
      },
      grid: {
        right: "20%"
      },
      legend: {
        data: ["Average"]
      },
      xAxis: [
        {
          type: "category",
          name: "Month",
          axisTick: {
            alignWithLabel: true
          },
          // prettier-ignore
          data: size > 0 ? data.date : []
        }
      ],
      yAxis: [
        {
          type: "value",
          name: "Average",
          position: "left",
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[1]
            }
          },
          axisLabel: {
            formatter: "{value}"
          }
        }
      ],
      series: [
        {
          name: "Average",
          type: "line",
          data: size > 0 ? data.average : []
        }
      ]
    }
  },
  salesRevenueAndAmount: function(data) {
    let size= Object.keys(data).length
    return {
      color: colors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross"
        }
      },
      grid: {
        right: "20%"
      },
      legend: {
        data: ["Quantity sales", "Income sales"]
      },
      xAxis: [
        {
          type: "category",
          name: "Dias",
          axisTick: {
            alignWithLabel: true
          },
          // prettier-ignore
          data: size > 0 ? data.date : []
        }
      ],
      yAxis: [
        {
          type: "value",
          name: "Quantity sales",
          position: "right",
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[0]
            }
          },
          axisLabel: {
            formatter: "{value}"
          }
        },
        {
          type: "value",
          name: "Income sales",
          position: "left",
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[1]
            }
          },
          axisLabel: {
            formatter: "$ {value}"
          }
        }
      ],
      series: [
        {
          name: "Quantity sales",
          type: "bar",
          data: size > 0 ? data.sales_quantity :[]
        },
        {
          name: "Income sales",
          type: "line",
          yAxisIndex: 1,
          data: size > 0 ? data.total_amount_sales : []
        }
      ]
    }
  },
  clientTotalSales: function(data) {
    let size= Object.keys(data).length
    return {
      color: colors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross"
        }
      },
      grid: {
        right: "20%"
      },
      legend: {
        data: ["Percentage", "Clients Quantity"]
      },
      xAxis: [
        {
          type: "category",
          name: "Quantity sales",
          axisTick: {
            alignWithLabel: true
          },
          // prettier-ignore
          data: size > 0 ? data.range : []
        }
      ],
      yAxis: [
        {
          type: "value",
          name: "Clients Quantity",
          position: "left",
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[0]
            }
          },
          axisLabel: {
            formatter: "{value}"
          }
        },
        {
          type: "value",
          name: "Percentage",
          position: "right",
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[1]
            }
          },
          axisLabel: {
            formatter: "{value}"
          }
        }
      ],
      series: [
        {
          name: "Clients Quantity",
          type: "bar",
          data: size > 0 ? data.clients_quantity : []
        },
        {
          name: "Percentage",
          type: "line",
          yAxisIndex: 1,
          data: size > 0 ? data.percentage : []
        }
      ]
    }
  },
  clientur: function(data) {
    let size= Object.keys(data).length
    return {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: size > 0 ? data.unique : 0, name: 'Unique' },
            { value:size > 0 ?  data.recurrent : 0, name: 'Recurring' }
          ]
        }
      ]
    };
  },
  bestSalesDays: function(data) {
    let size= Object.keys(data).length
    return {
      color: colors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross"
        }
      },
      grid: {
        right: "20%"
      },
      legend: {
        data: ["Quantity sales"]
      },
      xAxis: [
        {
          type: "category",
          name: "Days",
          axisTick: {
            alignWithLabel: true
          },
          // prettier-ignore
          data: size > 0 ? data.date : []
        }
      ],
      yAxis: [
        {
          type: "value",
          name: "Quantity sales",
          position: "left",
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[1]
            }
          },
          axisLabel: {
            formatter: "{value}"
          }
        }
      ],
      series: [
        {
          name: "Quantity sales",
          type: "line",
          data: size > 0 ? data.total : []
        }
      ]
    }
  },
  bestSalesHours: function(data) {
    let size= Object.keys(data).length
    return {
      color: colors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross"
        }
      },
      grid: {
        right: "20%"
      },
      legend: {
        data: ["Quantity sales"]
      },
      xAxis: [
        {
          type: "category",
          name: "Hours",
          axisTick: {
            alignWithLabel: true
          },
          // prettier-ignore
          data: size > 0 ? data.date : []
        }
      ],
      yAxis: [
        {
          type: "value",
          name: "Quantity sales",
          position: "left",
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: colors[1]
            }
          },
          axisLabel: {
            formatter: "{value}"
          }
        }
      ],
      series: [
        {
          name: "Quantity sales",
          type: "line",
          data: size > 0 ? data.total : []
        }
      ]
    }
  },


}



