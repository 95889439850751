<template>
  <div class="container-xl px-2 py-2 w-full">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 flex flex-row items-center justify-between">
        <div class="w-full flex flex-row items-center justify-end gap-3">
          <div class="w-20 relative" >
            <div
              class="rounded-lg animate-pulse  px-2 py-3 text-center text-gray-600 text-sm cursor-pointer inline-flex justify-center items-end">

            </div>
            <!-- Dropdown menu -->

          </div>
          <div class="animate-pulse">

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-xl px-2 w-full mb-4 ">
    <div class="grid grid-cols-12 gap-4">
      <div  class="h-120  col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12">
        <div class="grid grid-cols-3 gap-3">
          <div class="h-80 rounded-lg shadow bg-gray-100 animate-pulse"></div>
          <div class="h-80 rounded-lg shadow bg-gray-100 animate-pulse"></div>
          <div class="h-80 rounded-lg shadow bg-gray-100 animate-pulse"></div>
          <div class="h-80 rounded-lg shadow bg-gray-100 animate-pulse"></div>
          <div class="h-80 rounded-lg shadow bg-gray-100 animate-pulse"></div>
          <div class="h-80 rounded-lg shadow bg-gray-100 animate-pulse"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>
