<template>
  <div>
    <div>
      <div class="border-t bg-white border-b border-gray-200">
        <div class="container-xl px-4 md:px-2 py-4 h-20 md:h-20 mx-auto flex flex-row items-center justify-between">
          <!--Left Filter-->
          <div class="flex flex-row items-center justify-between md:justify-start">
            <h4 class="text-xl font-semibold leading-tight text-gray-800 flex items-center mr-0 md:mr-5">
              {{ t("reports.reports", {}, { locale: $store.state.Langs.selected.lang }) }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div>
      <TransitionFade>
        <div v-if="!currentProject">
          <NoContent />
        </div>
      </TransitionFade>
      <TransitionFade>
        <div v-if="currentProject && !isLoading">
          <div class="container-xl px-2 py-2 w-full">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 flex flex-row items-center justify-between">
                <div class="w-full flex flex-row items-center justify-end gap-3">
                  <div class="w-20 relative" @mouseleave="setMenuShortCutClose">
                    <div
                      class="rounded-lg shadow bg-white px-2 py-3 text-center text-gray-600 text-sm cursor-pointer inline-flex justify-center items-end"
                      @mouseover="setMenuShortCutOpen">
                      <span>
                        {{ isPlaceHolder }}
                        {{ t("dashboard.days", {}, { locale: $store.state.Langs.selected.lang }) }}
                      </span>
                      <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="currentColor"
                           aria-hidden="true">
                        <path fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd" />
                      </svg>
                    </div>
                    <!-- Dropdown menu -->
                    <TransitionFade>
                      <div class="absolute pt-2 overflow-y-auto -left-12 shadow-lg z-100 rounded-lg"
                           v-if="$store.state.Calendar.isMenuShortCutsOpen">
                        <ul class="dark:bg-gray-800 rounded-lg divide-y divide-gray-100 bg-white border">
                          <li
                            class="h-10 w-32 text-sm flex flex-col items-start justify-center cursor-pointer hover:bg-gray-50 transition ease-out duration-300"
                            :class="[$store.state.Calendar.isShortCutData.length - 1 == i ? 'rounded-b-lg' : '' || i == 0 ? 'rounded-t-lg' : '']"
                            v-for="(sc, i) in $store.state.Calendar.isShortCutData" :key="i"
                            @click="selectShortCutDates(sc)">
                            <span class="font-semibold text-gray-500 inline-block px-4">{{ sc
                              }} {{ t("dashboard.days", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                          </li>
                        </ul>
                      </div>
                    </TransitionFade>
                  </div>
                  <div>
                    <v-date-picker v-model="timeline" is-range
                                   :columns="$screens({ default: 1, sm: 1, md: 2, lg: 2, xl: 2 })" :masks="masks">
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="shadow w-auto flex justify-center items-center rounded-lg bg-white px-2 py-3"
                             v-on="inputEvents.end">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                               class="w-5 h-5 mx-1 text-gray-600">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                          <input v-model="inputValue.start"
                                 class="hidden md:block w-28 rounded-l-lg focus:outline-none text-sm text-center text-gray-600" />
                          <input :value="inputValue.end"
                                 class="hidden md:block w-28 rounded-r-lg focus:outline-none text-sm text-center text-gray-600" />
                        </div>
                      </template>
                    </v-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-xl px-2 w-full mb-4 ">
            <div class="grid grid-cols-12 gap-4">
              <div
                class="h-120  col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12"
                :class="{ 'animate-pulse': $store.state.Dashboard.loading }">
                <!--                <TimeLineSkeleton v-if="$store.state.Dashboard.loading" />
                                <ECharts ref="chart" :option="totalSalesByStore" />-->

                <div class="grid grid-cols-3 gap-3">
                  <div class="bg-white h-80 rounded-lg shadow">
                    <div class="row">
                      <div class="col-span-12">
                        <h5 class="left p-2">{{ t("reports.clientsUR", {}, { locale: $store.state.Langs.selected.lang })
                          }}</h5>
                      </div>
                    </div>
                    <div class="h-80 py-6" style="padding-top:0px !important">
                      <ECharts ref="chart" :option="clientUR" />
                    </div>
                  </div>
                  <div class="bg-white h-80 rounded-lg shadow">
                    <div class="row">
                      <div class="col-span-12">
                        <h5 class="left p-2">
                          {{ t("reports.transactions", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
                      </div>
                    </div>
                    <div class="row py-9 center m-10">
                      <div class="col-span-12">
                        <h3 class="text-center text-5xl">
                          {{ totalSalesQuantity }}
                        </h3>
                      </div>
                      <div class="col-span-12">
                        <h3 class="text-center text-2xl" style="color:rgba(107, 114, 128, 1)">
                          {{ t("reports.transactions", {}, { locale: $store.state.Langs.selected.lang }) }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="bg-white h-80 rounded-lg shadow">
                    <div class="row">
                      <div class="w-full">
                        <h5 class="left p-2">
                          {{ t("reports.buyFrequency", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
                      </div>
                    </div>
                    <div class="row py-9 center m-10">
                      <div class="col-span-12">
                        <h3 class="text-center text-5xl">
                          {{ frequencyBuy }}
                        </h3>
                      </div>
                      <div class="col-span-12">
                        <h3 class="text-center text-2xl" style="color:rgba(107, 114, 128, 1)">
                          {{ t("reports.buyFrequency", {}, { locale: $store.state.Langs.selected.lang }) }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="bg-white h-80 rounded-lg shadow">
                    <div class="row">
                      <div class="w-full">
                        <h5 class="left p-2">
                          {{ t("reports.totalIncome", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
                      </div>
                    </div>
                    <div class="row py-9 center m-10">
                      <div class="col-span-12">
                        <h3 class="text-center text-5xl">
                          ${{ income }}
                        </h3>
                      </div>
                      <div class="col-span-12">
                        <h3 class="text-center text-2xl" style="color:rgba(107, 114, 128, 1)">
                          {{ t("reports.totalIncome", {}, { locale: $store.state.Langs.selected.lang }) }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="bg-white h-80 rounded-lg shadow">
                    <div class="row">
                      <div class="w-full">
                        <h5 class="left p-2">
                          {{ t("reports.averageSales", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
                      </div>
                    </div>
                    <div class="row py-9 center m-10">
                      <div class="col-span-12">
                        <h3 class="text-center text-5xl">
                          ${{ averageSales }}
                        </h3>
                      </div>
                      <div class="col-span-12">
                        <h3 class="text-center text-2xl" style="color:rgba(107, 114, 128, 1)">
                          {{ t("reports.averageSales", {}, { locale: $store.state.Langs.selected.lang }) }}
                        </h3>
                      </div>

                    </div>
                  </div>
                  <div class="bg-white h-80 rounded-lg shadow">
                    <div class="row">
                      <div class="w-full">
                        <h5 class="left p-2">{{ t("reports.clients", {}, { locale: $store.state.Langs.selected.lang })
                          }}</h5>
                      </div>
                    </div>
                    <div class="row py-9 center m-10">
                      <div class="col-span-12">
                        <h3 class="text-center text-5xl">
                          {{ clientsTotal }}
                        </h3>
                      </div>
                      <div class="col-span-12">
                        <h3 class="text-center text-3xl" style="color:rgba(107, 114, 128, 1)">
                          {{ t("reports.clients", {}, { locale: $store.state.Langs.selected.lang }) }}
                        </h3>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-xl px-2  w-full mb-4 ">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 flex flex-row items-center justify-between">
                <h5 class="card-dashed-title mb-0 w-full">
                  {{ t("reports.totalSalesDays", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
              </div>
              <div
                class="h-96 bg-white col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 rounded-lg shadow p-2"
                :class="{ 'animate-pulse': $store.state.Dashboard.loading }">
                <TimeLineSkeleton v-if="isLoading" />
                <ECharts ref="chart" v-if="!isLoading" :option="salesTotalChart" />
              </div>
            </div>
          </div>
          <div class="container-xl px-2  w-full mb-4 ">
            <div class="grid grid-cols-12 gap-4">
              <div class="sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6">
                <div class="col-span-12 flex flex-row items-center justify-between">
                  <h5 class="card-dashed-title mb-4 w-full">
                    Promedio de ventas por tienda</h5>
                </div>
                <div
                  class="h-96 bg-white col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 rounded-lg shadow"
                  :class="{ 'animate-pulse': $store.state.Dashboard.loading }">
                  <TimeLineSkeleton v-if="$store.state.Dashboard.loading" />
                  <ECharts ref="chart" :option="averageSalesByStoreChart" />
                </div>
              </div>
              <div class="sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6">
                <div class="col-span-12 flex flex-row items-center justify-between">
                  <h5 class="card-dashed-title mb-4 w-full">
                    Total de ventas por tienda</h5>
                </div>
                <div
                  class="h-96 bg-white col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 rounded-lg shadow p-2"
                  :class="{ 'animate-pulse': $store.state.Dashboard.loading }">
                  <TimeLineSkeleton v-if="$store.state.Dashboard.loading" />
                  <ECharts ref="chart" :option="totalSalesByStore" />
                </div>
              </div>
            </div>
          </div>
          <div class="container-xl px-2  w-full mb-4">
            <div class=" grid grid-cols-12 gap-4">
              <div class="sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6">
                <div class="col-span-12 flex flex-row items-center justify-between">
                  <h5 class="card-dashed-title mb-4 w-full">
                    {{ t("reports.storesMostBuy", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
                </div>
                <div
                  class="h-60 bg-white rounded-lg  col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 overflow-y-auto"
                  :class="{ 'animate-pulse': $store.state.Dashboard.loading }">
                  <TimeLineSkeleton v-if="$store.state.Dashboard.loading" />
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                    <tr>
                      <th scope="col"
                          class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">
                        <div class="flex gap-2 cursor-pointer justify-start">
                          <span>{{ t("reports.store", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                        </div>
                      </th>
                      <th scope="col"
                          class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                        <div class="flex gap-2 cursor-pointer justify-start">
                          <span>{{ t("reports.income", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                        </div>
                      </th>
                      <th scope="col"
                          class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                        <div class="flex gap-2 cursor-pointer justify-start">
                          <span>{{ t("reports.transactions", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                        </div>
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-100">
                    <tr v-for="store in storesBuyMost" :key="store.store"
                        class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 border- border-gray-100">
                      <td class="px-4 text-left py-4 whitespace-wrap text-sm text-gray-500"
                      >
                        <div class="font-medium text-gray-800 flex flex-row">{{ store.store }}</div>
                      </td>
                      <td class="px-4 py-4">
                        <div class="flex flex-row">
                          <div class="text-gray-500">${{ store.total_amount_sales }}</div>
                        </div>
                      </td>
                      <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
                        <div class="text-gray-500">
                          {{ store.count_sales }}
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6">
                <div class="col-span-12 flex flex-row items-center justify-between">
                  <h5 class="card-dashed-title mb-4 w-full">
                    {{ t("reports.categoryMostBuy", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
                </div>
                <div
                  class="h-60 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 overflow-y-auto"
                  :class="{ 'animate-pulse': $store.state.Dashboard.loading }">
                  <TimeLineSkeleton v-if="$store.state.Dashboard.loading" />
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                    <tr>
                      <th scope="col"
                          class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">
                        <div class="flex gap-2 cursor-pointer justify-start">
                          <span>{{ t("reports.category", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                        </div>
                      </th>
                      <th scope="col"
                          class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                        <div class="flex gap-2 cursor-pointer justify-start">
                          <span>{{ t("reports.quantity", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                        </div>
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-100">
                    <tr v-for="category in categoryBuyMost" :key="category.category"
                        class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 border- border-gray-100">
                      <td class="px-4 text-left py-4 whitespace-wrap text-sm text-gray-500"
                      >
                        <div class="font-medium text-gray-800 flex flex-row">{{ category.category }}</div>
                      </td>
                      <td class="px-4 py-4">
                        <div class="flex flex-row">
                          <div class="text-gray-500">#{{ category.sales }}</div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="container-xl px-2  w-full mb-4">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 flex flex-row items-center justify-between">
                <h5 class="card-dashed-title mb-0 w-full">
                  {{ t("reports.averageSalesEvolution", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
              </div>
              <div
                class="h-96 bg-white col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 rounded-lg shadow"
                :class="{ 'animate-pulse': $store.state.Dashboard.loading }">
                <TimeLineSkeleton v-if="$store.state.Dashboard.loading" />
                <ECharts ref="chart" :option="averageSaleEvolution" />
              </div>
            </div>
          </div>
          <div class="container-xl px-2  w-full mb-4">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 flex flex-row items-center justify-between">
                <h5 class="card-dashed-title mb-0 w-full">
                  {{ t("reports.totalSalesMonth", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
              </div>
              <div
                class="h-96 bg-white col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 rounded-lg shadow"
                :class="{ 'animate-pulse': $store.state.Dashboard.loading }">
                <TimeLineSkeleton v-if="$store.state.Dashboard.loading" />
                <ECharts ref="chart" :option="salesRevenueAndAmount" />
              </div>
            </div>
          </div>
          <div class="container-xl px-2  w-full mb-4">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 flex flex-row items-center justify-between">
                <h5 class="card-dashed-title mb-0 w-full">
                  {{ t("reports.purchaseDistribution", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
              </div>
              <div
                class="h-96 bg-white col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 rounded-lg shadow"
                :class="{ 'animate-pulse': $store.state.Dashboard.loading }">
                <TimeLineSkeleton v-if="$store.state.Dashboard.loading" />
                <ECharts ref="chart" :option="clientTotalSales" />
              </div>
            </div>
          </div>
          <div class="container-xl px-2  w-full mb-4">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 flex flex-row items-center justify-between">
                <h5 class="card-dashed-title mb-0 w-full">
                  {{ t("reports.bestSalesDays", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
              </div>
              <div
                class="h-96 bg-white col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 rounded-lg shadow"
                :class="{ 'animate-pulse': $store.state.Dashboard.loading }">
                <TimeLineSkeleton v-if="$store.state.Dashboard.loading" />
                <ECharts ref="chart" :option="bestSalesDays" />
              </div>
            </div>
          </div>
          <div class="container-xl px-2  w-full mb-4">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 flex flex-row items-center justify-between">
                <h5 class="card-dashed-title mb-0 w-full">
                  {{ t("reports.bestSalesHours", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
              </div>
              <div
                class="h-96 bg-white col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 rounded-lg shadow"
                :class="{ 'animate-pulse': $store.state.Dashboard.loading }">
                <TimeLineSkeleton v-if="$store.state.Dashboard.loading" />
                <ECharts ref="chart" :option="bestSalesHours" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="currentProject && isLoading">
          <PrincipalMetricsSkeleton></PrincipalMetricsSkeleton>
        </div>
      </TransitionFade>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import NoContent from "@/components/Utils/NoContent"
import TransitionFade from "@/components/TransitionFade"
import * as echarts from "echarts"
import { h } from "vue"
import { createComponent } from "echarts-for-vue"
import TimeLineSkeleton from "@/components/Dashboard/TimeLineSkeleton"
//import { ReportServices } from "@/services/Reports"
import axiosClient from "@/config/AxiosClient"
import { ReportChartsConfig } from "@/config/ReportChartsConfig"
import PrincipalMetricsSkeleton from "@/components/Reports/PrincipalMetricsSkeleton.vue"

export default {
  components: {
    PrincipalMetricsSkeleton,
    TransitionFade,
    NoContent,
    ECharts: createComponent({ echarts, h }),
    TimeLineSkeleton
  },
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  name: `Reports`,
  data: function() {
    return {
      isLoading: false,
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      salesTotalChart: null,
      averageSalesByStoreChart: null,
      totalSalesByStore: null,
      clientsTotal: 0,
      income: 0,
      averageSales: 0,
      totalSalesQuantity: 0,
      totalClientsUR: 0,
      frequencyBuy: 0,
      categoryBuyMost: [],
      storesBuyMost: [],
      averageSaleEvolution: [],
      clientUR: null,
      salesRevenueAndAmount: null,
      conversionsOnlineChart: null,
      clientTotalSales: null,
      bestSalesDays: [],
      bestSalesHours: [],
      masks: {
        input: "YYYY-MM-DD"
      },
      timeline: {
        start: this.$store.state.Calendar.timeline.date_start,
        end: this.$store.state.Calendar.timeline.date_end
      },
      isPlaceHolder: 30
    }
  },
  created() {
    if (this.currentProject) {
      this.isLoading = true
      this.handleData()
    } else this.turnOffSplashScreen(100)
  },
  watch: {
    "$route.params.id": {
      handler(newVal) {
        if (this.currentProject) this.isLoading = true
        if (newVal) {
          this.currentProject = this.$route.params.id //updated currentproject
          this.handleData()
        }
      }
    },
    timeline: {
      handler(newVal) {
        if (newVal) {
          let newTimeline = {}
          newTimeline.date_start = this.$moment(this.timeline.start).format("YYYY-MM-DD 00:00:00")
          newTimeline.date_end = this.$moment(this.timeline.end).format("YYYY-MM-DD 23:59:59")
          this.$store.dispatch("Calendar/setNewTimeLine", newTimeline)
          this.handleData()
        }
      }
    },
    totalClientsUR: {
      handler(newVal) {
        if (newVal) {
          if (newVal != 0 && this.totalSalesQuantity != 0) {
            let calc = this.totalSalesQuantity / newVal
            this.frequencyBuy = Number((calc).toFixed(1))
          } else {
            this.frequencyBuy = 0
          }
        }
      }
    },
    totalSalesQuantity: {
      handler(newVal) {
        if (newVal) {
          if (newVal != 0 && this.totalClientsUR != 0) {
            let calc = newVal / this.totalClientsUR
            this.frequencyBuy = Number((calc).toFixed(3))
          } else {
            this.frequencyBuy = 0
          }
        }
      }
    }

  },
  methods: {
    doSomething: function() {
      this.$refs.chart.inst.getWidth()
    },
    setMenuShortCutOpen: function() {
      this.$store.dispatch("Calendar/openMenuShortCut")
    },
    setMenuShortCutClose: function() {
      this.$store.dispatch("Calendar/closeMenuShortCut")
    },
    selectShortCutDates: function(payload) {
      this.setMenuShortCutClose()
      this.isPlaceHolder = payload
      switch (payload) {
        case 7:
          this.timeline = {
            start: this.$moment(new Date()).subtract(7, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break
        case 14:
          this.timeline = {
            start: this.$moment(new Date()).subtract(14, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break

        case 30:
          this.timeline = {
            start: this.$moment(new Date()).subtract(30, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break
        case 60:
          this.timeline = {
            start: this.$moment(new Date()).subtract(60, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break
        case 90:
          this.timeline = {
            start: this.$moment(new Date()).subtract(90, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break
        default:
          this.timeline = {
            start: this.$store.state.Calendar.timeline.date_start,
            end: this.$store.state.Calendar.timeline.date_end
          }
          break
      }
    },
    handleData: function() {
      this.isLoading = true
      this.getClientUR()
      this.getTotalSales()
      this.getAverageSalesByStore()
      this.salesByStore()
      this.totalclients()
      this.totalIncome()
      this.getTotalSalesQuantity()
      this.getAverageSales()
      this.getStoresBuyMost()
      this.getCategoryBuyMost()
      this.getAverageSaleEvolution()
      this.getSalesRevenueAndAmount()
      this.getClientTotalSales()
      this.getBestSalesDays()
      this.getBestSalesHours()
    },
    getTotalSales: async function() {
      let currentProject = this.currentProject
      let params = {
        filters: "totalSales",
        conditions: {
          date: {
            start_date: this.$store.state.Calendar.timeline.date_start,
            end_date: this.$store.state.Calendar.timeline.date_end
          }
        }
      }

      await axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        let size = Object.keys(res.data.data.totalSales).length
        this.salesTotalChart = ReportChartsConfig.salesTotal(size > 0 ? res.data.data.totalSales.data_formatted : {})
        this.turnOffSplashScreen(1000)
      })

    },
    getAverageSalesByStore: async function() {
      let currentProject = this.currentProject

      let params = {
        filters: "averageSalesByStore",
        conditions: {
          date: {
            start_date: this.$store.state.Calendar.timeline.date_start,
            end_date: this.$store.state.Calendar.timeline.date_end
          }
        }
      }

      axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        let size = Object.keys(res.data.data.averageSalesByStore).length
        this.averageSalesByStoreChart = ReportChartsConfig.averageSalesByStore(size > 0 ? res.data.data.averageSalesByStore.data_formated : {})
        this.turnOffSplashScreen(1000)
      })
    },
    salesByStore: async function() {
      let currentProject = this.currentProject

      let params = {
        filters: "salesByStore",
        conditions: {
          date: {
            start_date: this.$store.state.Calendar.timeline.date_start,
            end_date: this.$store.state.Calendar.timeline.date_end
          }
        }
      }

      axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        let size = Object.keys(res.data.data.salesByStore).length
        this.totalSalesByStore = ReportChartsConfig.salesByStore(size > 0 ? res.data.data.salesByStore.data_formated : {})
        this.turnOffSplashScreen(1000)
      })
    },
    totalclients: async function() {
      let currentProject = this.currentProject

      let params = {
        filters: "clientsTotal",
        conditions: {
          date: {
            start_date: this.$store.state.Calendar.timeline.date_start,
            end_date: this.$store.state.Calendar.timeline.date_end
          }
        }
      }

      axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        this.clientsTotal = this.abbreviateNumber(res.data.data.clientsTotal.total)//Math.floor(res.data.data.clientsTotal.total / 1000) + " K"
        this.turnOffSplashScreen(1000)
      })
    },
    totalIncome: async function() {
      let currentProject = this.currentProject

      let params = {
        filters: "income",
        conditions: {
          date: {
            start_date: this.$store.state.Calendar.timeline.date_start,
            end_date: this.$store.state.Calendar.timeline.date_end
          }
        }
      }

      axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        this.income = Math.floor(parseInt(res.data.data.income.total) / 1000000) + " M"
        this.turnOffSplashScreen(1000)
      })
    },
    getTotalSalesQuantity: async function() {
      let currentProject = this.currentProject

      let params = {
        filters: "totalSalesQuantity",
        conditions: {
          date: {
            start_date: this.$store.state.Calendar.timeline.date_start,
            end_date: this.$store.state.Calendar.timeline.date_end
          }
        }
      }

      axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        this.totalSalesQuantity = res.data.data.totalSalesQuantity.total
        this.turnOffSplashScreen(1000)
      })
    },
    getAverageSales: async function() {
      let currentProject = this.currentProject

      let params = {
        filters: "averageSales",
        conditions: {
          date: {
            start_date: this.$store.state.Calendar.timeline.date_start,
            end_date: this.$store.state.Calendar.timeline.date_end
          }
        }
      }

      axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        this.averageSales = this.abbreviateNumber(res.data.data.averageSales.total)
        this.turnOffSplashScreen(1000)
      })
    },
    getStoresBuyMost: async function() {
      let currentProject = this.currentProject

      let params = {
        filters: "storesBuyMost",
        conditions: {
          date: {
            start_date: this.$store.state.Calendar.timeline.date_start,
            end_date: this.$store.state.Calendar.timeline.date_end
          }
        }
      }

      axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        this.storesBuyMost = res.data.data.storesBuyMost
        this.turnOffSplashScreen(1000)
      })
    },
    getCategoryBuyMost: async function() {
      let currentProject = this.currentProject

      let params = {
        filters: "categoryBuyMost",
        conditions: {
          date: {
            start_date: this.$store.state.Calendar.timeline.date_start,
            end_date: this.$store.state.Calendar.timeline.date_end
          }
        }
      }

      axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        this.categoryBuyMost = res.data.data.categoryBuyMost
        this.turnOffSplashScreen(1000)
      })
    },
    getAverageSaleEvolution: async function() {
      let currentProject = this.currentProject

      let params = {
        filters: "averageSaleEvolution",
        conditions: {
          date: {
            start_date: this.$store.state.Calendar.timeline.date_start,
            end_date: this.$store.state.Calendar.timeline.date_end
          }
        }
      }

      axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        let size = Object.keys(res.data.data.averageSaleEvolution).length
        this.averageSaleEvolution = ReportChartsConfig.averageSaleEvolution(size > 0 ? res.data.data.averageSaleEvolution.data_formatted : {})
        this.turnOffSplashScreen(1000)
      })
    },
    getSalesRevenueAndAmount: async function() {
      let currentProject = this.currentProject

      let params = {
        filters: "salesRevenueAndAmount",
        conditions: {
          date: {
            start_date: this.$store.state.Calendar.timeline.date_start,
            end_date: this.$store.state.Calendar.timeline.date_end
          }
        }
      }

      axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        let size = Object.keys(res.data.data.salesRevenueAndAmount).length
        this.salesRevenueAndAmount = ReportChartsConfig.salesRevenueAndAmount(size > 0 ? res.data.data.salesRevenueAndAmount.data_formatted : {})
        this.turnOffSplashScreen(1000)
      })
    },
    getClientTotalSales: async function() {
      let currentProject = this.currentProject

      let params = {
        filters: "clientTotalSales",
        conditions: {
          conditions: {
            date: {
              start_date: this.$store.state.Calendar.timeline.date_start,
              end_date: this.$store.state.Calendar.timeline.date_end
            }
          }
        }
      }

      axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        let size = Object.keys(res.data.data.clientTotalSales).length
        this.clientTotalSales = ReportChartsConfig.clientTotalSales(size > 0 ? res.data.data.clientTotalSales.data_formatted : {})
        this.turnOffSplashScreen(1000)
        this.isLoading = false

      })
    },
    getClientUR: async function() {
      let currentProject = this.currentProject

      let params = {
        filters: "uniqueAndRecurringCustomers",
        conditions: {
          date: {
            start_date: this.$store.state.Calendar.timeline.date_start,
            end_date: this.$store.state.Calendar.timeline.date_end
          }
        }
      }

      axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        let size = Object.keys(res.data.data.uniqueAndRecurringCustomers).length
        let data = {}
        let totalClientsUR = 0
        if (size > 0) {
          data = res.data.data.uniqueAndRecurringCustomers
          totalClientsUR = data.unique + data.recurrent
        }

        this.clientUR = ReportChartsConfig.clientur(data)
        this.totalClientsUR = totalClientsUR

        this.turnOffSplashScreen(1000)
      })
    },
    getBestSalesDays: async function() {
      let currentProject = this.currentProject

      let params = {
        filters: "bestSalesDays",
        conditions: {
          date: {
            start_date: this.$store.state.Calendar.timeline.date_start,
            end_date: this.$store.state.Calendar.timeline.date_end
          }
        }
      }

      axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        let size = Object.keys(res.data.data.bestSalesDays).length
        let data = {}

        if (size > 0) {
          data = res.data.data.bestSalesDays
        }

        this.bestSalesDays = ReportChartsConfig.bestSalesDays(data.data_formatted)

        this.turnOffSplashScreen(1000)
      })
    },
    getBestSalesHours: async function() {
      let currentProject = this.currentProject

      let params = {
        filters: "bestSalesHours",
        conditions: {
          date: {
            start_date: this.$store.state.Calendar.timeline.date_start,
            end_date: this.$store.state.Calendar.timeline.date_end
          }
        }
      }

      axiosClient.get(`api/2.0/project/${currentProject}/reports`, { params: params }).then((res) => {
        let size = Object.keys(res.data.data.bestSalesHours).length
        let data = {}

        if (size > 0) {
          data = res.data.data.bestSalesHours
        }

        this.bestSalesHours = ReportChartsConfig.bestSalesHours(data.data_formatted)

        this.turnOffSplashScreen(1000)
      })
    },
    abbreviateNumber: function(number) {
      if (number >= 1000000) {
        return (number / 1000000).toFixed(1) + "M"
      }
      if (number >= 1000) {
        return (number / 1000).toFixed(1) + "k"
      }
      return number.toString()
    },
    turnOffSplashScreen: function(time) {
      setTimeout(() => {
        this.isLoading = false
      }, time)
    }
  }
}
</script>
